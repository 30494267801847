import { CademyError } from '@shared/domain-shared';
import { SearchIndexCourseFilters, SearchIndexEducatorFilters } from '@shared/types';

export type LocationWithGeoCoordinates = {
    name: string;
    ceremonialArea: string;
    region: string;
    flag: '🇬🇧' | '🏴󠁧󠁢󠁷󠁬󠁳󠁿' | '🏴󠁧󠁢󠁥󠁮󠁧󠁿' | '🏴󠁧󠁢󠁳󠁣󠁴󠁿';
    latitude: number;
    longitude: number;
};

export const locationsWithGeoCoordinates: Array<LocationWithGeoCoordinates> = [
    {
        name: 'London',
        ceremonialArea: 'London',
        region: 'London',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.5072178,
        longitude: -0.1275862,
    },
    {
        name: 'Birmingham',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.48624299999999,
        longitude: -1.890401,
    },
    {
        name: 'Glasgow',
        ceremonialArea: 'Glasgow',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.864237,
        longitude: -4.251806,
    },
    {
        name: 'Liverpool',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4083714,
        longitude: -2.9915726,
    },
    {
        name: 'Bristol',
        ceremonialArea: 'Bristol',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.454513,
        longitude: -2.58791,
    },
    {
        name: 'Manchester',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4807593,
        longitude: -2.2426305,
    },
    {
        name: 'Sheffield',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.38112899999999,
        longitude: -1.470085,
    },
    {
        name: 'Leeds',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.8007554,
        longitude: -1.5490774,
    },
    {
        name: 'Edinburgh',
        ceremonialArea: 'Edinburgh',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.953252,
        longitude: -3.188267,
    },
    {
        name: 'Leicester',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6368778,
        longitude: -1.1397592,
    },
    {
        name: 'Coventry',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.4128163,
        longitude: -1.5089521,
    },
    {
        name: 'Bradford',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.7937996,
        longitude: -1.7563583,
    },
    {
        name: 'Cardiff',
        ceremonialArea: 'South Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.483707,
        longitude: -3.1680962,
    },
    {
        name: 'Belfast',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.59728500000001,
        longitude: -5.93012,
    },
    {
        name: 'Nottingham',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.9540223,
        longitude: -1.1549892,
    },
    {
        name: 'Hull',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.76762360000001,
        longitude: -0.3274198,
    },
    {
        name: 'Newcastle upon Tyne',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.978252,
        longitude: -1.61778,
    },
    {
        name: 'Stoke-on-Trent',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.0033369,
        longitude: -2.1827408,
    },
    {
        name: 'Southampton',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.9105468,
        longitude: -1.4049018,
    },
    {
        name: 'Derby',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.9225301,
        longitude: -1.4746186,
    },
    {
        name: 'Portsmouth',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8197675,
        longitude: -1.0879769,
    },
    {
        name: 'Brighton',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8229402,
        longitude: -0.1362672,
    },
    {
        name: 'Plymouth',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.3754565,
        longitude: -4.1426565,
    },
    {
        name: 'Northampton',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.23706499999999,
        longitude: -0.8944420999999999,
    },
    {
        name: 'Reading',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.45512009999999,
        longitude: -0.9787475,
    },
    {
        name: 'Luton',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.8786707,
        longitude: -0.4200255,
    },
    {
        name: 'Wolverhampton',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5868159,
        longitude: -2.1256587,
    },
    {
        name: 'Bolton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.57686469999999,
        longitude: -2.4282192,
    },
    {
        name: 'Aberdeen',
        ceremonialArea: 'Aberdeen',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.1498891,
        longitude: -2.0937528,
    },
    {
        name: 'Bournemouth',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.7220101,
        longitude: -1.8667169,
    },
    {
        name: 'Norwich',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6292567,
        longitude: 1.2978802,
    },
    {
        name: 'Swindon',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.5583777,
        longitude: -1.7809759,
    },
    {
        name: 'Swansea',
        ceremonialArea: 'West Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.62144,
        longitude: -3.943645999999999,
    },
    {
        name: 'Milton Keynes',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.0406224,
        longitude: -0.7594171,
    },
    {
        name: 'Southend-on-Sea',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.5459269,
        longitude: 0.7077123,
    },
    {
        name: 'Middlesbrough',
        ceremonialArea: 'North Yorkshire',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.574227,
        longitude: -1.234956,
    },
    {
        name: 'Peterborough',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.57031689999999,
        longitude: -0.2407996999999999,
    },
    {
        name: 'Sunderland',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.906869,
        longitude: -1.383801,
    },
    {
        name: 'Warrington',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3900441,
        longitude: -2.5969501,
    },
    {
        name: 'Huddersfield',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.645792,
        longitude: -1.785035,
    },
    {
        name: 'Slough',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.51053839999999,
        longitude: -0.5950405999999999,
    },
    {
        name: 'Oxford',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.7520209,
        longitude: -1.2577263,
    },
    {
        name: 'York',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.9614205,
        longitude: -1.0739108,
    },
    {
        name: 'Poole',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.71505,
        longitude: -1.987248,
    },
    {
        name: 'Ipswich',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.056736,
        longitude: 1.14822,
    },
    {
        name: 'Telford',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6775873,
        longitude: -2.4672611,
    },
    {
        name: 'Cambridge',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.1950788,
        longitude: 0.1312729,
    },
    {
        name: 'Dundee',
        ceremonialArea: 'Dundee',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.462018,
        longitude: -2.970721,
    },
    {
        name: 'Gloucester',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.8642449,
        longitude: -2.238156,
    },
    {
        name: 'Blackpool',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.81674229999999,
        longitude: -3.0370485,
    },
    {
        name: 'Birkenhead',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.38811639999999,
        longitude: -3.0299176,
    },
    {
        name: 'Watford',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.66112829999999,
        longitude: -0.3970199,
    },
    {
        name: 'Sale',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.42556099999999,
        longitude: -2.323702,
    },
    {
        name: 'Colchester',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.895927,
        longitude: 0.8918740000000001,
    },
    {
        name: 'Newport, Gwent',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.584151,
        longitude: -2.997664,
    },
    {
        name: 'Solihull',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.411811,
        longitude: -1.77761,
    },
    {
        name: 'High Wycombe',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.628611,
        longitude: -0.7482289999999999,
    },
    {
        name: 'Exeter',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.72603669999999,
        longitude: -3.5274889,
    },
    {
        name: 'Gateshead',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.95268,
        longitude: -1.603411,
    },
    {
        name: 'Blackburn',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.8744129,
        longitude: -3.6235554,
    },
    {
        name: 'Cheltenham',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.8993855,
        longitude: -2.0782533,
    },
    {
        name: 'Maidstone',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.378051,
        longitude: -3.435973,
    },
    {
        name: 'Chelmsford',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.7355868,
        longitude: 0.4685497,
    },
    {
        name: 'Salford',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.48752349999999,
        longitude: -2.2901264,
    },
    {
        name: 'Basildon',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.57608399999999,
        longitude: 0.488736,
    },
    {
        name: 'Doncaster',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.52282,
        longitude: -1.128462,
    },
    {
        name: 'Basingstoke',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.26654,
        longitude: -1.0923964,
    },
    {
        name: 'Worthing',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.81787,
        longitude: -0.372882,
    },
    {
        name: 'Eastbourne',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.768035,
        longitude: 0.290472,
    },
    {
        name: 'Crawley',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.1091401,
        longitude: -0.1872275,
    },
    {
        name: 'Rochdale',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.6097136,
        longitude: -2.1561,
    },
    {
        name: 'Rotherham',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4326035,
        longitude: -1.3635009,
    },
    {
        name: 'Stockport',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.41063159999999,
        longitude: -2.1575332,
    },
    {
        name: 'Gillingham, Kent',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.386322,
        longitude: 0.551438,
    },
    {
        name: 'Sutton Coldfield',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.57038499999999,
        longitude: -1.824042,
    },
    {
        name: 'Woking',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.316774,
        longitude: -0.5600349,
    },
    {
        name: 'Wigan',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5450645,
        longitude: -2.6325074,
    },
    {
        name: 'Lincoln',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.9451889,
        longitude: -0.1601246,
    },
    {
        name: 'Oldham',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5409298,
        longitude: -2.1113659,
    },
    {
        name: 'Wakefield',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.683298,
        longitude: -1.505924,
    },
    {
        name: 'St Helens',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.456307,
        longitude: -2.737095,
    },
    {
        name: 'Worcester',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.193636,
        longitude: -2.221575,
    },
    {
        name: 'Hemel Hempstead',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.753241,
        longitude: -0.448632,
    },
    {
        name: 'Bath',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3781018,
        longitude: -2.3596827,
    },
    {
        name: 'Preston',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.763201,
        longitude: -2.70309,
    },
    {
        name: 'Rayleigh',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.586385,
        longitude: 0.6048709999999999,
    },
    {
        name: 'Barnsley',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.55263,
        longitude: -1.479726,
    },
    {
        name: 'Stevenage',
        ceremonialArea: 'Hertfordshrie',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.903761,
        longitude: -0.196612,
    },
    {
        name: 'Hastings',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.854259,
        longitude: 0.573453,
    },
    {
        name: 'Southport',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.645708,
        longitude: -3.010113,
    },
    {
        name: 'Darlington',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.52361,
        longitude: -1.559458,
    },
    {
        name: 'Bedford',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.1386394,
        longitude: -0.4667782,
    },
    {
        name: 'Halifax',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.72702,
        longitude: -1.85754,
    },
    {
        name: 'Hartlepool',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.691745,
        longitude: -1.212926,
    },
    {
        name: 'Chesterfield',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.235048,
        longitude: -1.421629,
    },
    {
        name: 'Nuneaton',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.520489,
        longitude: -1.465382,
    },
    {
        name: 'Grimsby',
        ceremonialArea: 'Lincolnshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.567471,
        longitude: -0.080784,
    },
    {
        name: 'Weston-super-Mare',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.345606,
        longitude: -2.9612624,
    },
    {
        name: 'Chester',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.193392,
        longitude: -2.893075,
    },
    {
        name: 'St Albans',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.752725,
        longitude: -0.339436,
    },
    {
        name: 'Harlow',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.767787,
        longitude: 0.087806,
    },
    {
        name: 'Guildford',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.23622,
        longitude: -0.570409,
    },
    {
        name: 'Aylesbury',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.815606,
        longitude: -0.8084,
    },
    {
        name: 'Stockton-on-Tees',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.5704551,
        longitude: -1.3289821,
    },
    {
        name: 'Dudley',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.512255,
        longitude: -2.081112,
    },
    {
        name: 'Bracknell',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.41419819999999,
        longitude: -0.752517,
    },
    {
        name: 'Derry/Londonderry',
        ceremonialArea: 'County Londonderry',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.9966124,
        longitude: -7.3085748,
    },
    {
        name: 'Redditch',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.30897,
        longitude: -1.940936,
    },
    {
        name: 'Batley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.717028,
        longitude: -1.635083,
    },
    {
        name: 'Scunthorpe',
        ceremonialArea: 'Lincolnshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.378051,
        longitude: -3.435973,
    },
    {
        name: 'Burnley',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.7892877,
        longitude: -2.2405035,
    },
    {
        name: 'Eastleigh',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.967182,
        longitude: -1.374688,
    },
    {
        name: 'Mansfield',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.147195,
        longitude: -1.198674,
    },
    {
        name: 'Chatham',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.380952,
        longitude: 0.52213,
    },
    {
        name: 'Bury',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5933498,
        longitude: -2.2966054,
    },
    {
        name: 'Newcastle-under-Lyme',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.013208,
        longitude: -2.2273002,
    },
    {
        name: 'West Bromwich',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.517664,
        longitude: -1.995159,
    },
    {
        name: 'Paisley',
        ceremonialArea: 'Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.847258,
        longitude: -4.440113999999999,
    },
    {
        name: 'South Shields',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.999424,
        longitude: -1.427406,
    },
    {
        name: 'Burton upon Trent',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.81402809999999,
        longitude: -1.6371359,
    },
    {
        name: 'Rugby',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.370878,
        longitude: -1.265032,
    },
    {
        name: 'East Kilbride',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.76435240000001,
        longitude: -4.1769988,
    },
    {
        name: 'Carlisle',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.892473,
        longitude: -2.932931,
    },
    {
        name: 'Harrogate',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.99212,
        longitude: -1.541812,
    },
    {
        name: 'Gosport',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.794995,
        longitude: -1.117547,
    },
    {
        name: 'Grays',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.4784037,
        longitude: 0.3230151,
    },
    {
        name: 'Shrewsbury',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.70730289999999,
        longitude: -2.7553268,
    },
    {
        name: 'Ashford, Kent',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.14510070000001,
        longitude: 0.8739631,
    },
    {
        name: 'Tamworth',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.633584,
        longitude: -1.691032,
    },
    {
        name: 'Crewe',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.10040499999999,
        longitude: -2.4438209,
    },
    {
        name: 'Lowestoft',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.48113799999999,
        longitude: 1.753449,
    },
    {
        name: 'Atherton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.52401099999999,
        longitude: -2.49227,
    },
    {
        name: 'Walsall',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.586214,
        longitude: -1.982919,
    },
    {
        name: 'Stafford',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.806693,
        longitude: -2.12066,
    },
    {
        name: 'Cannock',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.69994080000001,
        longitude: -2.0218293,
    },
    {
        name: 'Bognor Regis',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.782998,
        longitude: -0.673061,
    },
    {
        name: 'Walton-on-Thames',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.38847,
        longitude: -0.416969,
    },
    {
        name: 'Tynemouth',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.0175869,
        longitude: -1.4255819,
    },
    {
        name: 'Bamber Bridge',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.7282629,
        longitude: -2.6606344,
    },
    {
        name: 'Maidenhead',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.52182089999999,
        longitude: -0.7242048,
    },
    {
        name: 'Washington',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.89743199999999,
        longitude: -1.517366,
    },
    {
        name: 'Paignton',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.43508,
        longitude: -3.564235,
    },
    {
        name: 'Filton',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.507864,
        longitude: -2.576467,
    },
    {
        name: 'Rochester',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.38800000000001,
        longitude: 0.506721,
    },
    {
        name: 'Farnborough',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.2868939,
        longitude: -0.7526149999999999,
    },
    {
        name: 'Dewsbury',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.689833,
        longitude: -1.6296949,
    },
    {
        name: 'Loughborough',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.772099,
        longitude: -1.206166,
    },
    {
        name: 'Newtownabbey',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.6792422,
        longitude: -5.9591102,
    },
    {
        name: 'Margate',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.38964600000001,
        longitude: 1.3868339,
    },
    {
        name: 'Stourbridge',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.456891,
        longitude: -2.148731,
    },
    {
        name: 'Craigavon',
        ceremonialArea: 'County Armagh',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.450852,
        longitude: -6.3938712,
    },
    {
        name: 'Hereford',
        ceremonialArea: 'Herefordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.05639799999999,
        longitude: -2.715974,
    },
    {
        name: 'Widnes',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.361024,
        longitude: -2.733637,
    },
    {
        name: 'Canterbury',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.280233,
        longitude: 1.0789089,
    },
    {
        name: 'Taunton',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.015344,
        longitude: -3.106849,
    },
    {
        name: 'Runcorn',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.342078,
        longitude: -2.729673,
    },
    {
        name: 'Wrexham',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.04304,
        longitude: -2.992494,
    },
    {
        name: 'Scarborough',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.283113,
        longitude: -0.399752,
    },
    {
        name: 'Bangor, County Down',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 53.22739,
        longitude: -4.129263,
    },
    {
        name: 'Ellesmere Port',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.279812,
        longitude: -2.897404,
    },
    {
        name: 'Wallasey',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.426521,
        longitude: -3.066215,
    },
    {
        name: 'Corby',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.49229829999999,
        longitude: -0.6842332999999999,
    },
    {
        name: 'Royal Tunbridge Wells',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.132377,
        longitude: 0.263695,
    },
    {
        name: 'Kettering',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.396322,
        longitude: -0.7302489999999999,
    },
    {
        name: 'Halesowen',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.449845,
        longitude: -2.050526,
    },
    {
        name: 'Aldershot',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.248366,
        longitude: -0.7557509,
    },
    {
        name: 'Littlehampton',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.811057,
        longitude: -0.5386609,
    },
    {
        name: 'Bebington',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3530146,
        longitude: -3.0075315,
    },
    {
        name: 'Gravesend',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.44188399999999,
        longitude: 0.370759,
    },
    {
        name: 'Kidderminster',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.388596,
        longitude: -2.2496839,
    },
    {
        name: 'Livingston',
        ceremonialArea: 'West Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.90070799999999,
        longitude: -3.518068,
    },
    {
        name: 'Barry',
        ceremonialArea: 'South Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.3997529,
        longitude: -3.2825859,
    },
    {
        name: 'Royal Leamington Spa',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.2851905,
        longitude: -1.5200789,
    },
    {
        name: 'Macclesfield',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.258663,
        longitude: -2.119287,
    },
    {
        name: 'Christchurch',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.735777,
        longitude: -1.778586,
    },
    {
        name: 'Altrincham',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.38690459999999,
        longitude: -2.3488983,
    },
    {
        name: 'Weymouth',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.6099306,
        longitude: -2.4546253,
    },
    {
        name: 'Brentwood',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.620475,
        longitude: 0.3071749,
    },
    {
        name: 'Hamilton',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.77763299999999,
        longitude: -4.053679,
    },
    {
        name: 'Ewell',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3506319,
        longitude: -0.2533376,
    },
    {
        name: 'Clacton-on-Sea',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.789534,
        longitude: 1.153035,
    },
    {
        name: 'Keighley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.867795,
        longitude: -1.912358,
    },
    {
        name: 'Beeston',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.92392,
        longitude: -1.212394,
    },
    {
        name: 'Dunfermline',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.071741,
        longitude: -3.452151,
    },
    {
        name: 'Willenhall',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.585017,
        longitude: -2.057635,
    },
    {
        name: 'Folkestone',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.081397,
        longitude: 1.169456,
    },
    {
        name: 'Smethwick',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.492401,
        longitude: -1.965207,
    },
    {
        name: 'Esher',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.369487,
        longitude: -0.365927,
    },
    {
        name: 'Sittingbourne',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.340402,
        longitude: 0.7315959999999999,
    },
    {
        name: 'Wellingborough',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.302419,
        longitude: -0.6939639999999999,
    },
    {
        name: 'Bootle',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.44325500000001,
        longitude: -2.998895,
    },
    {
        name: 'Durham',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.77525,
        longitude: -1.584852,
    },
    {
        name: 'Welwyn Garden City',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.8031689,
        longitude: -0.208661,
    },
    {
        name: 'Bloxwich',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.617397,
        longitude: -2.0043417,
    },
    {
        name: 'Neath',
        ceremonialArea: 'West Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.656991,
        longitude: -3.805476,
    },
    {
        name: 'Cumbernauld',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.945668,
        longitude: -3.992534,
    },
    {
        name: 'Kingswinford',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.4981719,
        longitude: -2.169995,
    },
    {
        name: 'Lancaster',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.0449072,
        longitude: -2.799346700000001,
    },
    {
        name: 'Shoreham-by-Sea',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8342086,
        longitude: -0.2715558,
    },
    {
        name: 'Torquay',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.4619209,
        longitude: -3.525315,
    },
    {
        name: 'Horsham',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.062883,
        longitude: -0.325858,
    },
    {
        name: 'Carlton',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.966631,
        longitude: -1.083383,
    },
    {
        name: 'Kirkcaldy',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.1168249,
        longitude: -3.158137,
    },
    {
        name: 'Crosby',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4892924,
        longitude: -3.0462309,
    },
    {
        name: "King's Lynn",
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.7516798,
        longitude: 0.4022963000000001,
    },
    {
        name: 'Horndean',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.915062,
        longitude: -1.0016844,
    },
    {
        name: 'Hinckley',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5454549,
        longitude: -1.37667,
    },
    {
        name: 'Swadlincote',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.771318,
        longitude: -1.554997,
    },
    {
        name: 'Winchester',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.059771,
        longitude: -1.310142,
    },
    {
        name: 'Bridgend',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.504286,
        longitude: -3.576945,
    },
    {
        name: 'Yeovil',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.942061,
        longitude: -2.633308,
    },
    {
        name: 'Sutton-in-Ashfield',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.1253529,
        longitude: -1.2602057,
    },
    {
        name: 'West Bridgford',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.935673,
        longitude: -1.134017,
    },
    {
        name: 'Perth',
        ceremonialArea: 'Perth and Kinross',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.394994,
        longitude: -3.430838,
    },
    {
        name: 'Havant',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8518324,
        longitude: -0.9847131999999998,
    },
    {
        name: 'Inverness',
        ceremonialArea: 'Highland',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.477773,
        longitude: -4.224721,
    },
    {
        name: 'Ashton-under-Lyne',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4876815,
        longitude: -2.0961077,
    },
    {
        name: 'Banbury',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.0629009,
        longitude: -1.339775,
    },
    {
        name: 'Cwmbrân',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.6496546,
        longitude: -3.0317889,
    },
    {
        name: 'Cheshunt',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.70264050000001,
        longitude: -0.0343787,
    },
    {
        name: 'Northwich',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.25868029999999,
        longitude: -2.5181321,
    },
    {
        name: 'Ayr',
        ceremonialArea: 'South Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.458564,
        longitude: -4.629179000000001,
    },
    {
        name: 'Kilmarnock',
        ceremonialArea: 'East Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.614719,
        longitude: -4.498792,
    },
    {
        name: 'Wokingham',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.410457,
        longitude: -0.833861,
    },
    {
        name: 'Salisbury',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.068785,
        longitude: -1.794472,
    },
    {
        name: 'Andover',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.2111975,
        longitude: -1.4919233,
    },
    {
        name: 'Lisburn',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.516246,
        longitude: -6.058010599999999,
    },
    {
        name: 'Boston',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.97893999999999,
        longitude: -0.026577,
    },
    {
        name: 'Hatfield',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.763366,
        longitude: -0.22309,
    },
    {
        name: 'Llanelli',
        ceremonialArea: 'Dyfed',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.680886,
        longitude: -4.160248,
    },
    {
        name: 'Merthyr Tydfil',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.74872999999999,
        longitude: -3.381646,
    },
    {
        name: 'Wallsend',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.991512,
        longitude: -1.529375,
    },
    {
        name: 'Tipton',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.52625,
        longitude: -2.066058,
    },
    {
        name: 'Grantham',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.912524,
        longitude: -0.643582,
    },
    {
        name: 'Dover',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.1278758,
        longitude: 1.3134027,
    },
    {
        name: 'Barrow-in-Furness',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.108967,
        longitude: -3.218894,
    },
    {
        name: 'Bexhill-on-Sea',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8499062,
        longitude: 0.4662071,
    },
    {
        name: 'Lytham St Annes',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.756629,
        longitude: -3.0213193,
    },
    {
        name: 'Bridgwater',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.127889,
        longitude: -3.003632,
    },
    {
        name: 'Middleton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.545921,
        longitude: -2.2019479,
    },
    {
        name: 'Coatbridge',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.862241,
        longitude: -4.019337,
    },
    {
        name: 'Hoddesdon',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.76000699999999,
        longitude: -0.015042,
    },
    {
        name: 'Braintree',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.9,
        longitude: 0.5499999999999999,
    },
    {
        name: 'Fareham',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8548464,
        longitude: -1.1865868,
    },
    {
        name: 'Trowbridge',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.319664,
        longitude: -2.208853,
    },
    {
        name: 'Worksop',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.309302,
        longitude: -1.122745,
    },
    {
        name: 'Kirkby',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.48121,
        longitude: -2.891012,
    },
    {
        name: 'Jarrow',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.980297,
        longitude: -1.482757,
    },
    {
        name: 'Greenock',
        ceremonialArea: 'Inverclyde',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.95647599999999,
        longitude: -4.771983,
    },
    {
        name: 'Ramsgate',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.335545,
        longitude: 1.419895,
    },
    {
        name: 'Urmston',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.448717,
        longitude: -2.374697,
    },
    {
        name: 'Castleford',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.72346599999999,
        longitude: -1.345968,
    },
    {
        name: 'Bury St Edmunds',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.24690409999999,
        longitude: 0.7112514,
    },
    {
        name: 'Caerphilly',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.578829,
        longitude: -3.218134,
    },
    {
        name: 'Newbury',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.401409,
        longitude: -1.3231139,
    },
    {
        name: 'Leighton Buzzard',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.9196839,
        longitude: -0.6606569999999999,
    },
    {
        name: 'Fleet',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.277283,
        longitude: -0.842655,
    },
    {
        name: 'Tonbridge',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.195043,
        longitude: 0.27568,
    },
    {
        name: 'Eccles',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4807151,
        longitude: -2.3596801,
    },
    {
        name: 'Great Yarmouth',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.598233,
        longitude: 1.728047,
    },
    {
        name: 'Herne Bay',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.370922,
        longitude: 1.127771,
    },
    {
        name: "Bishop's Stortford",
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.8721635,
        longitude: 0.1536502,
    },
    {
        name: 'Leyland',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.697931,
        longitude: -2.695478,
    },
    {
        name: 'Ilkeston',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.96792490000001,
        longitude: -1.3072669,
    },
    {
        name: 'Camberley',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3353899,
        longitude: -0.742856,
    },
    {
        name: 'Abingdon-on-Thames',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.67078,
        longitude: -1.2879529,
    },
    {
        name: 'Arnold',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.378051,
        longitude: -3.435973,
    },
    {
        name: 'Newark-on-Trent',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.0700391,
        longitude: -0.80657,
    },
    {
        name: 'Long Eaton',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.898446,
        longitude: -1.269778,
    },
    {
        name: 'Aldridge',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6110324,
        longitude: -1.9142056,
    },
    {
        name: 'Bletchley',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.002175,
        longitude: -0.7501369999999999,
    },
    {
        name: 'Cleethorpes',
        ceremonialArea: 'Lincolnshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.557378,
        longitude: -0.029435,
    },
    {
        name: 'Blyth',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.126957,
        longitude: -1.510277,
    },
    {
        name: 'Canvey Island',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.517083,
        longitude: 0.578411,
    },
    {
        name: 'Chorley',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.653511,
        longitude: -2.632596,
    },
    {
        name: 'Glenrothes',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.20084199999999,
        longitude: -3.15986,
    },
    {
        name: 'Prescot',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.428674,
        longitude: -2.804564,
    },
    {
        name: 'Great Malvern',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.1116462,
        longitude: -2.32607,
    },
    {
        name: 'Port Talbot',
        ceremonialArea: 'West Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.5946799,
        longitude: -3.784097,
    },
    {
        name: 'Longbenton',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.0119467,
        longitude: -1.590343,
    },
    {
        name: 'Haywards Heath',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.999041,
        longitude: -0.106333,
    },
    {
        name: 'Stirling',
        ceremonialArea: 'Stirling',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.1165227,
        longitude: -3.9369029,
    },
    {
        name: 'Chester-le-Street',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.8591161,
        longitude: -1.5740888,
    },
    {
        name: 'Woodley',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.4516523,
        longitude: -0.902918,
    },
    {
        name: 'Redcar',
        ceremonialArea: 'North Yorkshire',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.5974636,
        longitude: -1.0779515,
    },
    {
        name: 'Borehamwood',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.657728,
        longitude: -0.272308,
    },
    {
        name: 'Airdrie',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.866267,
        longitude: -3.962566,
    },
    {
        name: 'Wilmslow',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.328,
        longitude: -2.229409,
    },
    {
        name: 'Redhill',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.239208,
        longitude: -0.16988,
    },
    {
        name: 'Whitley Bay',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.046389,
        longitude: -1.4512989,
    },
    {
        name: 'Accrington',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.753609,
        longitude: -2.37218,
    },
    {
        name: 'Hitchin',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.94921,
        longitude: -0.283414,
    },
    {
        name: 'Coalville',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.724569,
        longitude: -1.3677109,
    },
    {
        name: 'Denton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.455203,
        longitude: -2.114613,
    },
    {
        name: 'Wath-upon-Dearne',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5029116,
        longitude: -1.339493,
    },
    {
        name: 'Chippenham',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.461514,
        longitude: -2.1195157,
    },
    {
        name: 'Chadderton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.544941,
        longitude: -2.140409,
    },
    {
        name: 'Falkirk',
        ceremonialArea: 'Falkirk',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.00187750000001,
        longitude: -3.7839131,
    },
    {
        name: 'Exmouth',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.619957,
        longitude: -3.413702,
    },
    {
        name: 'Fulwood',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.788019,
        longitude: -2.7126175,
    },
    {
        name: 'Rowley Regis',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.488674,
        longitude: -2.046085,
    },
    {
        name: 'Hyde',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.452977,
        longitude: -2.082786,
    },
    {
        name: 'Bromsgrove',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.335589,
        longitude: -2.061906,
    },
    {
        name: 'Romiley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.414926,
        longitude: -2.077023,
    },
    {
        name: 'Spalding',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.79010160000001,
        longitude: -0.1537024,
    },
    {
        name: 'Bridlington',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.08535,
        longitude: -0.198802,
    },
    {
        name: 'Hucknall',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.034152,
        longitude: -1.20289,
    },
    {
        name: 'Billericay',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.627903,
        longitude: 0.418397,
    },
    {
        name: 'Skelmersdale',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.549284,
        longitude: -2.774735,
    },
    {
        name: 'Epsom',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.33510039999999,
        longitude: -0.2637125,
    },
    {
        name: 'Billingham',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.612537,
        longitude: -1.290961,
    },
    {
        name: 'Wickford',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.611309,
        longitude: 0.52068,
    },
    {
        name: 'Letchworth Garden City',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.979074,
        longitude: -0.226624,
    },
    {
        name: 'Wisbech',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.666317,
        longitude: 0.158797,
    },
    {
        name: 'Stroud',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.745734,
        longitude: -2.217758,
    },
    {
        name: 'Irvine',
        ceremonialArea: 'North Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.61156690000001,
        longitude: -4.6696364,
    },
    {
        name: 'Pudsey',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.795766,
        longitude: -1.6761082,
    },
    {
        name: 'Brighouse',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.699729,
        longitude: -1.782501,
    },
    {
        name: 'Lichfield',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.681602,
        longitude: -1.831672,
    },
    {
        name: 'Whitstable',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.361047,
        longitude: 1.024256,
    },
    {
        name: 'Morecambe',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.074166,
        longitude: -2.864968,
    },
    {
        name: 'Dumfries',
        ceremonialArea: 'Dumfries and Galloway',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.070859,
        longitude: -3.60512,
    },
    {
        name: 'Glossop',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4433284,
        longitude: -1.948907,
    },
    {
        name: 'Dunstable',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.885644,
        longitude: -0.52039,
    },
    {
        name: 'Leatherhead',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.29640699999999,
        longitude: -0.33112,
    },
    {
        name: 'St Neots',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.2300836,
        longitude: -0.265103,
    },
    {
        name: 'Bicester',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.8999993,
        longitude: -1.1531524,
    },
    {
        name: 'Warwick',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.28231599999999,
        longitude: -1.584927,
    },
    {
        name: 'Loughton',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.655942,
        longitude: 0.068161,
    },
    {
        name: 'Motherwell',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.78320919999999,
        longitude: -3.9810968,
    },
    {
        name: 'Bilston',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.56559499999999,
        longitude: -2.074088,
    },
    {
        name: 'Windsor',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.48034209999999,
        longitude: -0.6157136999999999,
    },
    {
        name: 'Barnstaple',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.0781599,
        longitude: -4.058338,
    },
    {
        name: 'Rushden',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.289125,
        longitude: -0.600363,
    },
    {
        name: 'Burgess Hill',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.954469,
        longitude: -0.128701,
    },
    {
        name: 'Chichester',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.83761000000001,
        longitude: -0.774936,
    },
    {
        name: 'Brierley Hill',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.4821669,
        longitude: -2.120309,
    },
    {
        name: 'Deal',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.222491,
        longitude: 1.401661,
    },
    {
        name: 'Sevenoaks',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.27241000000001,
        longitude: 0.190898,
    },
    {
        name: 'Sedgley',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5428691,
        longitude: -2.1217238,
    },
    {
        name: 'Radcliffe',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.561449,
        longitude: -2.326769,
    },
    {
        name: 'Bedworth',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.481392,
        longitude: -1.468869,
    },
    {
        name: 'Rutherglen',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.828972,
        longitude: -4.2242689,
    },
    {
        name: 'Harpenden',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.81845999999999,
        longitude: -0.358953,
    },
    {
        name: 'Beverley',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.841963,
        longitude: -0.435093,
    },
    {
        name: 'Pontypridd',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.60077399999999,
        longitude: -3.342314,
    },
    {
        name: 'Aberdare',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.716154,
        longitude: -3.451816,
    },
    {
        name: 'Winsford',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.19426199999999,
        longitude: -2.51967,
    },
    {
        name: 'Nelson',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.835187,
        longitude: -2.219377,
    },
    {
        name: 'Yate',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.54158899999999,
        longitude: -2.414323,
    },
    {
        name: 'Witney',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.7859365,
        longitude: -1.4850544,
    },
    {
        name: 'Wishaw',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.773543,
        longitude: -3.919354,
    },
    {
        name: 'East Grinstead',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.128742,
        longitude: -0.014468,
    },
    {
        name: 'Pontefract',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.691688,
        longitude: -1.308648,
    },
    {
        name: 'Weybridge',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3716269,
        longitude: -0.457904,
    },
    {
        name: 'Didcot',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.60804400000001,
        longitude: -1.24484,
    },
    {
        name: 'Heywood',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.592628,
        longitude: -2.22565,
    },
    {
        name: 'Northfleet',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.44107200000001,
        longitude: 0.33694,
    },
    {
        name: 'Morley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.744513,
        longitude: -1.598045,
    },
    {
        name: 'Stratford-upon-Avon',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.19173,
        longitude: -1.708298,
    },
    {
        name: 'Totton',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.9141239,
        longitude: -1.4985934,
    },
    {
        name: 'Ballymena',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.8652935,
        longitude: -6.280221300000001,
    },
    {
        name: 'Heswall',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3284285,
        longitude: -3.0987304,
    },
    {
        name: 'Penarth',
        ceremonialArea: 'South Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.438798,
        longitude: -3.173507,
    },
    {
        name: 'Kendal',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.32800599999999,
        longitude: -2.74629,
    },
    {
        name: 'Cambuslang',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.820982,
        longitude: -4.164891,
    },
    {
        name: 'Stanford-le-Hope',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.514121,
        longitude: 0.42854,
    },
    {
        name: 'Pontypool',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.700369,
        longitude: -3.045268,
    },
    {
        name: 'Hertford',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.795756,
        longitude: -0.08115699999999999,
    },
    {
        name: 'Shipley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.835927,
        longitude: -1.776978,
    },
    {
        name: 'Ashford, Surrey',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.14510070000001,
        longitude: 0.8739631,
    },
    {
        name: 'Burntwood',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.679936,
        longitude: -1.921781,
    },
    {
        name: 'Ashton-in-Makerfield',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4869569,
        longitude: -2.642566,
    },
    {
        name: 'Kirkby-in-Ashfield',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.100963,
        longitude: -1.269612,
    },
    {
        name: 'Colwyn Bay',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.29318199999999,
        longitude: -3.72764,
    },
    {
        name: 'Bearsden',
        ceremonialArea: 'East Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.92169,
        longitude: -4.335525,
    },
    {
        name: 'Egham',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.428825,
        longitude: -0.547876,
    },
    {
        name: 'Newtownards',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.5913787,
        longitude: -5.693170299999999,
    },
    {
        name: 'Ashington',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.182978,
        longitude: -1.5659829,
    },
    {
        name: 'Carrickfergus',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.7261871,
        longitude: -5.8101207,
    },
    {
        name: 'Farnworth',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.545838,
        longitude: -2.40396,
    },
    {
        name: 'Haverhill',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.082766,
        longitude: 0.440945,
    },
    {
        name: 'Stretford',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.446622,
        longitude: -2.308713,
    },
    {
        name: 'Darwen',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.695451,
        longitude: -2.46874,
    },
    {
        name: 'Melton Mowbray',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.76692689999999,
        longitude: -0.8879085999999999,
    },
    {
        name: 'Evesham',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.09214900000001,
        longitude: -1.94677,
    },
    {
        name: 'Frome',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.230751,
        longitude: -2.320096,
    },
    {
        name: 'Cramlington',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.086136,
        longitude: -1.5808,
    },
    {
        name: 'Thetford',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.412856,
        longitude: 0.751657,
    },
    {
        name: 'Bentley',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.378051,
        longitude: -3.435973,
    },
    {
        name: 'Ditton',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.294626,
        longitude: 0.4538530000000001,
    },
    {
        name: 'Farnham',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.214321,
        longitude: -0.798802,
    },
    {
        name: 'Stalybridge',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.48380599999999,
        longitude: -2.0500759,
    },
    {
        name: 'Ferndown',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.807364,
        longitude: -1.899776,
    },
    {
        name: 'Newton Abbot',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.52890499999999,
        longitude: -3.608359999999999,
    },
    {
        name: 'Peterlee',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.762441,
        longitude: -1.32895,
    },
    {
        name: 'Newry',
        ceremonialArea: 'County Armagh',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.1751024,
        longitude: -6.3402299,
    },
    {
        name: 'Staines-upon-Thames',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.43148,
        longitude: -0.515525,
    },
    {
        name: 'Bushey',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.64761499999999,
        longitude: -0.35842,
    },
    {
        name: 'Witham',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.7978049,
        longitude: 0.6372179,
    },
    {
        name: 'Newton Mearns',
        ceremonialArea: 'East Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.771176,
        longitude: -4.336615,
    },
    {
        name: 'Hedge End',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.91364280000001,
        longitude: -1.3038762,
    },
    {
        name: 'Heanor',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.013807,
        longitude: -1.35377,
    },
    {
        name: 'Clydebank',
        ceremonialArea: 'West Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.900099,
        longitude: -4.404774,
    },
    {
        name: 'Portishead',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.485155,
        longitude: -2.767948,
    },
    {
        name: 'Congleton',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.162856,
        longitude: -2.218923,
    },
    {
        name: 'Kidsgrove',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.086191,
        longitude: -2.240836,
    },
    {
        name: 'Thatcham',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.40580500000001,
        longitude: -1.266468,
    },
    {
        name: 'Ormskirk',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.568935,
        longitude: -2.885057,
    },
    {
        name: 'Rhyl',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.31914099999999,
        longitude: -3.491634,
    },
    {
        name: 'Huntingdon',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.33146,
        longitude: -0.182552,
    },
    {
        name: 'Thornaby-on-Tees',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.5403076,
        longitude: -1.2936359,
    },
    {
        name: 'Newton Aycliffe',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.61598799999999,
        longitude: -1.575577,
    },
    {
        name: 'Newport, Isle of Wight',
        ceremonialArea: 'Isle of Wight',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.584151,
        longitude: -2.997664,
    },
    {
        name: 'Maghull',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5156922,
        longitude: -2.945368,
    },
    {
        name: 'St Austell',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.3403779,
        longitude: -4.7834252,
    },
    {
        name: 'Fleetwood',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.916661,
        longitude: -3.035673,
    },
    {
        name: 'Corsett',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.378051,
        longitude: -3.435973,
    },
    {
        name: 'Plymstock',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.3564804,
        longitude: -4.089707,
    },
    {
        name: 'Swinton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.511443,
        longitude: -2.3462325,
    },
    {
        name: 'Oldbury',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.504923,
        longitude: -2.015907,
    },
    {
        name: 'Market Harborough',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.475769,
        longitude: -0.9215169999999999,
    },
    {
        name: 'Bishop Auckland',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.663822,
        longitude: -1.678788,
    },
    {
        name: 'Wednesfield',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.59625699999999,
        longitude: -2.08341,
    },
    {
        name: 'Cheadle Hulme',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.380149,
        longitude: -2.193189,
    },
    {
        name: 'Rickmansworth',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.638763,
        longitude: -0.4741309999999999,
    },
    {
        name: 'Stubbington',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.824707,
        longitude: -1.2134268,
    },
    {
        name: 'Horley',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.173516,
        longitude: -0.172109,
    },
    {
        name: 'Skegness',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.146403,
        longitude: 0.337881,
    },
    {
        name: 'Wombwell',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.521016,
        longitude: -1.399319,
    },
    {
        name: 'Daventry',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.25602749999999,
        longitude: -1.1625942,
    },
    {
        name: 'Ryde',
        ceremonialArea: 'Isle of Wight',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.729952,
        longitude: -1.163282,
    },
    {
        name: 'Elgin',
        ceremonialArea: 'Moray',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.64945400000001,
        longitude: -3.318485,
    },
    {
        name: 'Gorleston-on-Sea',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.570645,
        longitude: 1.734211,
    },
    {
        name: 'Hindley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.532996,
        longitude: -2.580635,
    },
    {
        name: 'Coleraine',
        ceremonialArea: 'County Londonderry',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 55.1325802,
        longitude: -6.6646102,
    },
    {
        name: 'Broadstairs',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.360163,
        longitude: 1.432038,
    },
    {
        name: 'Windlesham',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.36456099999999,
        longitude: -0.665847,
    },
    {
        name: 'Rugeley',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.761515,
        longitude: -1.935967,
    },
    {
        name: 'Amersham',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.6755179,
        longitude: -0.6072409999999999,
    },
    {
        name: 'Felixstowe',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.961726,
        longitude: 1.351255,
    },
    {
        name: 'Golborne',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.47542499999999,
        longitude: -2.598247,
    },
    {
        name: 'Droylsden',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.480784,
        longitude: -2.148716,
    },
    {
        name: 'Rawtenstall',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.7004862,
        longitude: -2.3014596,
    },
    {
        name: 'Blackwood',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.668935,
        longitude: -3.195787,
    },
    {
        name: 'Westhoughton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.54896,
        longitude: -2.525881,
    },
    {
        name: 'Belper',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.0243899,
        longitude: -1.477616,
    },
    {
        name: 'Burnham-on-Sea',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.240139,
        longitude: -2.993858,
    },
    {
        name: 'Falmouth',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.15257099999999,
        longitude: -5.066269999999999,
    },
    {
        name: 'Arbroath',
        ceremonialArea: 'Angus',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.559107,
        longitude: -2.591543,
    },
    {
        name: 'Droitwich Spa',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.2615914,
        longitude: -2.1526355,
    },
    {
        name: 'Newton-le-Willows',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.452889,
        longitude: -2.63508,
    },
    {
        name: 'Chesham',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.378051,
        longitude: -3.435973,
    },
    {
        name: 'Whitehaven',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.549699,
        longitude: -3.589233,
    },
    {
        name: 'Bishopbriggs',
        ceremonialArea: 'East Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.904185,
        longitude: -4.228494,
    },
    {
        name: 'Potters Bar',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.696636,
        longitude: -0.175948,
    },
    {
        name: 'Seaford',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.7734669,
        longitude: 0.101108,
    },
    {
        name: 'Sudbury',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.041047,
        longitude: 0.726706,
    },
    {
        name: 'Godalming',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.18573199999999,
        longitude: -0.612808,
    },
    {
        name: 'Reigate',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.237276,
        longitude: -0.205883,
    },
    {
        name: 'Formby',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.558271,
        longitude: -3.068743,
    },
    {
        name: 'Antrim',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.7195338,
        longitude: -6.2072498,
    },
    {
        name: 'Alfreton',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.09744999999999,
        longitude: -1.382256,
    },
    {
        name: 'Coseley',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.544158,
        longitude: -2.0889858,
    },
    {
        name: 'Royton',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.567124,
        longitude: -2.12132,
    },
    {
        name: 'Retford',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.32135599999999,
        longitude: -0.9455030000000001,
    },
    {
        name: 'Clifton',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.46615509999999,
        longitude: -2.6200362,
    },
    {
        name: 'Caterham',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.28029600000001,
        longitude: -0.08161589999999999,
    },
    {
        name: 'Bathgate',
        ceremonialArea: 'West Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.9024,
        longitude: -3.643118,
    },
    {
        name: 'Bingley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.847389,
        longitude: -1.833781,
    },
    {
        name: 'Gainsborough',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.400575,
        longitude: -0.774465,
    },
    {
        name: 'Buxton',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.259082,
        longitude: -1.91483,
    },
    {
        name: 'Chapeltown',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.8152222,
        longitude: -1.5321797,
    },
    {
        name: 'Seaham',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.840346,
        longitude: -1.337517,
    },
    {
        name: 'Renfrew',
        ceremonialArea: 'Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.875322,
        longitude: -4.391541999999999,
    },
    {
        name: 'Swanscombe',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.4452254,
        longitude: 0.3031822,
    },
    {
        name: 'Ingleby Barwick',
        ceremonialArea: 'North Yorkshire',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.524482,
        longitude: -1.306944,
    },
    {
        name: 'Workington',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.643569,
        longitude: -3.542752,
    },
    {
        name: 'Holmfirth',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.571744,
        longitude: -1.786292,
    },
    {
        name: 'Goole',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.7044721,
        longitude: -0.874562,
    },
    {
        name: 'Truro',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.263195,
        longitude: -5.051041,
    },
    {
        name: 'Hailsham',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.864612,
        longitude: 0.255234,
    },
    {
        name: 'Camborne',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.21277,
        longitude: -5.2947749,
    },
    {
        name: 'Newquay',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.41549699999999,
        longitude: -5.073719,
    },
    {
        name: 'Crowborough',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.06085299999999,
        longitude: 0.165894,
    },
    {
        name: 'Ossett',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.681091,
        longitude: -1.578877,
    },
    {
        name: 'Normanton',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.700876,
        longitude: -1.417148,
    },
    {
        name: 'Maldon',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.73180499999999,
        longitude: 0.6714479999999999,
    },
    {
        name: 'South Ockenden',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.521164,
        longitude: 0.2943529,
    },
    {
        name: 'Stanley',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.868948,
        longitude: -1.698841,
    },
    {
        name: 'March',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.551716,
        longitude: 0.08862199999999999,
    },
    {
        name: 'Kenilworth',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.349557,
        longitude: -1.58073,
    },
    {
        name: 'Gatley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.39286,
        longitude: -2.2446419,
    },
    {
        name: 'Berkhamsted',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.75976600000001,
        longitude: -0.5678569000000001,
    },
    {
        name: 'Walkden',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5237032,
        longitude: -2.3986825,
    },
    {
        name: 'Kirkintilloch',
        ceremonialArea: 'East Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.9392401,
        longitude: -4.1561656,
    },
    {
        name: 'Ampthill',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.0272503,
        longitude: -0.4951417,
    },
    {
        name: 'Stowmarket',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.188902,
        longitude: 0.9977119999999999,
    },
    {
        name: 'Dereham',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.68161800000001,
        longitude: 0.937827,
    },
    {
        name: 'Kempston',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.11791479999999,
        longitude: -0.4967915000000001,
    },
    {
        name: 'Whitefield',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.552083,
        longitude: -2.299201,
    },
    {
        name: 'Dukinfield',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.478764,
        longitude: -2.094523,
    },
    {
        name: 'Clevedon',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.4284337,
        longitude: -2.8280061,
    },
    {
        name: 'Brownhills',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.646054,
        longitude: -1.931056,
    },
    {
        name: 'Redruth',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.233022,
        longitude: -5.226666,
    },
    {
        name: 'Gerrards Cross',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.582362,
        longitude: -0.5575,
    },
    {
        name: 'Newmarket',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.24487999999999,
        longitude: 0.407962,
    },
    {
        name: 'Minster',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.336651,
        longitude: 1.314436,
    },
    {
        name: 'Dronfield',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.30227900000001,
        longitude: -1.467959,
    },
    {
        name: 'Tewkesbury',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.992265,
        longitude: -2.15796,
    },
    {
        name: 'Harwich',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.86,
        longitude: 1.24,
    },
    {
        name: 'Musselburgh',
        ceremonialArea: 'East Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.941941,
        longitude: -3.053918,
    },
    {
        name: 'Sandhurst',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3462419,
        longitude: -0.804268,
    },
    {
        name: 'Dorchester',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.7111639,
        longitude: -2.441181,
    },
    {
        name: 'Dumbarton',
        ceremonialArea: 'West Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.945287,
        longitude: -4.564554,
    },
    {
        name: 'Stamford',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.65128199999999,
        longitude: -0.4802159999999999,
    },
    {
        name: 'Faversham',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.315994,
        longitude: 0.889358,
    },
    {
        name: 'New Milton',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.7531235,
        longitude: -1.6550806,
    },
    {
        name: 'Hythe, Hampshire',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.071739,
        longitude: 1.081937,
    },
    {
        name: 'Dinnington',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.913049,
        longitude: -2.852379,
    },
    {
        name: 'Irlam',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.444266,
        longitude: -2.42142,
    },
    {
        name: 'Stourport-on-Severn',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.3417839,
        longitude: -2.277593,
    },
    {
        name: 'Darlaston',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5704506,
        longitude: -2.0354721,
    },
    {
        name: 'Belshill',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.816761,
        longitude: -4.026536,
    },
    {
        name: 'Ely',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.399539,
        longitude: 0.262363,
    },
    {
        name: 'Buckley',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.17005,
        longitude: -3.0814879,
    },
    {
        name: 'Biggleswade',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.086938,
        longitude: -0.26422,
    },
    {
        name: 'Portslade-by-Sea',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.842729,
        longitude: -0.21423,
    },
    {
        name: 'Tiverton',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.902049,
        longitude: -3.491207,
    },
    {
        name: 'Peacehaven',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.793209,
        longitude: -0.005174,
    },
    {
        name: 'Ware',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.810437,
        longitude: -0.028177,
    },
    {
        name: 'Earl Shilton',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5763906,
        longitude: -1.3113676,
    },
    {
        name: 'Leek',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.10915199999999,
        longitude: -2.023393,
    },
    {
        name: 'Oswestry',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.857148,
        longitude: -3.056412,
    },
    {
        name: 'Bideford',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.016684,
        longitude: -4.206666,
    },
    {
        name: 'Mirfield',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.680122,
        longitude: -1.696713,
    },
    {
        name: 'Ripley',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.05281900000001,
        longitude: -1.405792,
    },
    {
        name: 'Ystrad Mynach',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.642387,
        longitude: -3.235099,
    },
    {
        name: 'Emsworth',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.848132,
        longitude: -0.937928,
    },
    {
        name: 'Horwich',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.598398,
        longitude: -2.554762,
    },
    {
        name: 'Hazel Grove',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.376299,
        longitude: -2.112004,
    },
    {
        name: 'Hebburn',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.972283,
        longitude: -1.52199,
    },
    {
        name: 'Omagh',
        ceremonialArea: 'County Tyrone',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.5977149,
        longitude: -7.3099596,
    },
    {
        name: 'Melksham',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.370447,
        longitude: -2.137629,
    },
    {
        name: 'Kesgrave',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.06256699999999,
        longitude: 1.233907,
    },
    {
        name: 'Yarm-Eaglescliffe',
        ceremonialArea: 'North Yorkshire',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.504523,
        longitude: -1.354759,
    },
    {
        name: 'Thornton',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.79166499999999,
        longitude: -1.848581,
    },
    {
        name: 'Rothwell',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.422681,
        longitude: -0.803219,
    },
    {
        name: 'Sandbach',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.146561,
        longitude: -2.367382,
    },
    {
        name: 'Horsforth',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.842177,
        longitude: -1.636099,
    },
    {
        name: 'Wantage',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.588868,
        longitude: -1.426453,
    },
    {
        name: 'Wednesbury',
        ceremonialArea: 'West Midlands',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.552888,
        longitude: -2.02208,
    },
    {
        name: 'Frimley',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.316221,
        longitude: -0.743239,
    },
    {
        name: 'Peterhead',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.50812299999999,
        longitude: -1.784066,
    },
    {
        name: 'Alton',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.150719,
        longitude: -0.973177,
    },
    {
        name: 'Waltham Abbey',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.685034,
        longitude: 0.002723,
    },
    {
        name: 'Selby',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.78352400000001,
        longitude: -1.067189,
    },
    {
        name: 'Yeadon',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.865153,
        longitude: -1.684203,
    },
    {
        name: 'Eastwood',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.01811499999999,
        longitude: -1.308544,
    },
    {
        name: 'Rawmarsh',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4616366,
        longitude: -1.3442806,
    },
    {
        name: 'Spennymoor',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.69772099999999,
        longitude: -1.58552,
    },
    {
        name: 'Devizes',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3489069,
        longitude: -1.994828,
    },
    {
        name: 'Adwick-le-Street',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5722088,
        longitude: -1.189055,
    },
    {
        name: 'Colne',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.85564,
        longitude: -2.176991,
    },
    {
        name: 'Maesteg',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.60856200000001,
        longitude: -3.660466,
    },
    {
        name: 'Failsworth',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.508131,
        longitude: -2.164444,
    },
    {
        name: 'Larne',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.8578003,
        longitude: -5.8236224,
    },
    {
        name: 'Mansfield Woodhouse',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.166472,
        longitude: -1.189765,
    },
    {
        name: 'Cobham',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.32878299999999,
        longitude: -0.410856,
    },
    {
        name: 'Litherland',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4720649,
        longitude: -2.9963154,
    },
    {
        name: 'Great Wyrley',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.65928,
        longitude: -2.010228,
    },
    {
        name: 'Marple',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.397349,
        longitude: -2.061683,
    },
    {
        name: 'Bangor, Gwynedd',
        ceremonialArea: 'Gwynedd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.22739,
        longitude: -4.129263,
    },
    {
        name: 'Shaw',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5831442,
        longitude: -2.0953755,
    },
    {
        name: 'Hoyland',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.498492,
        longitude: -1.44056,
    },
    {
        name: 'Rochford',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.582071,
        longitude: 0.706515,
    },
    {
        name: 'Biddulph',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.119197,
        longitude: -2.171608,
    },
    {
        name: 'Ebbw Vale',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.777532,
        longitude: -3.206151,
    },
    {
        name: 'Liversedge',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.709984,
        longitude: -1.697018,
    },
    {
        name: 'Calne',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.43933,
        longitude: -2.003884,
    },
    {
        name: 'Nantwich',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.06718499999999,
        longitude: -2.524102,
    },
    {
        name: 'Sleaford',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.0003079,
        longitude: -0.40965,
    },
    {
        name: 'Houghton Regis',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.9038619,
        longitude: -0.524733,
    },
    {
        name: 'Tonypandy',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.62435199999999,
        longitude: -3.458067,
    },
    {
        name: 'Lewes',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.87387200000001,
        longitude: 0.00878,
    },
    {
        name: 'Westbury',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.256659,
        longitude: -2.185772,
    },
    {
        name: 'Warminster',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.204629,
        longitude: -2.181078,
    },
    {
        name: 'Poulton-le-Fylde',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.843964,
        longitude: -2.986281,
    },
    {
        name: 'Ramsbottom',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.648457,
        longitude: -2.32049,
    },
    {
        name: 'Addlestone',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.369813,
        longitude: -0.488641,
    },
    {
        name: 'Dorking',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.232202,
        longitude: -0.332378,
    },
    {
        name: 'Barrhead',
        ceremonialArea: 'East Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.79671099999999,
        longitude: -4.397714000000001,
    },
    {
        name: 'St Andrews',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.3397753,
        longitude: -2.7967214,
    },
    {
        name: 'Bonnyrigg',
        ceremonialArea: 'Midlothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.874917,
        longitude: -3.106337,
    },
    {
        name: 'Hale',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.37891,
        longitude: -2.343509,
    },
    {
        name: 'Romsey',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.98893,
        longitude: -1.49658,
    },
    {
        name: 'Bramhall',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.367647,
        longitude: -2.164072,
    },
    {
        name: 'Shotton',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.2115447,
        longitude: -3.0380242,
    },
    {
        name: 'Guisborough',
        ceremonialArea: 'North Yorkshire',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.536241,
        longitude: -1.049544,
    },
    {
        name: 'Keynsham',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.415059,
        longitude: -2.502526,
    },
    {
        name: 'Swanley',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.396531,
        longitude: 0.177326,
    },
    {
        name: 'St Ives, Cambridgeshire',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.20838579999999,
        longitude: -5.490886400000001,
    },
    {
        name: 'Northallerton',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.3377838,
        longitude: -1.4310027,
    },
    {
        name: 'Louth',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.365962,
        longitude: -0.007710999999999999,
    },
    {
        name: 'Bedlington',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.131664,
        longitude: -1.599454,
    },
    {
        name: 'Gorseinon',
        ceremonialArea: 'West Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.669543,
        longitude: -4.041545,
    },
    {
        name: 'Brixham',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.39514,
        longitude: -3.513924,
    },
    {
        name: 'Cirencester',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.718495,
        longitude: -1.968243,
    },
    {
        name: 'Prestatyn',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.336512,
        longitude: -3.407613,
    },
    {
        name: 'Royston, Hertfordshire',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.048142,
        longitude: -0.024066,
    },
    {
        name: 'Blantyre',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.793868,
        longitude: -4.09492,
    },
    {
        name: 'North Hykeham',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.18257,
        longitude: -0.5870959,
    },
    {
        name: "Connah's Quay",
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.22006039999999,
        longitude: -3.0589149,
    },
    {
        name: 'Saffron Walden',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.022593,
        longitude: 0.239215,
    },
    {
        name: 'Banbridge',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.348729,
        longitude: -6.2704803,
    },
    {
        name: 'Grangemouth',
        ceremonialArea: 'Falkirk',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.0097152,
        longitude: -3.7227698,
    },
    {
        name: 'Tyldesley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.514508,
        longitude: -2.464474,
    },
    {
        name: 'Heysham',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.04199,
        longitude: -2.894475,
    },
    {
        name: 'Haydock',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.46761619999999,
        longitude: -2.6849601,
    },
    {
        name: 'Stone',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.907932,
        longitude: -2.144045,
    },
    {
        name: 'Ryton',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.9728909,
        longitude: -1.7629735,
    },
    {
        name: 'Aberystwyth',
        ceremonialArea: 'Dyfed',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 52.41530299999999,
        longitude: -4.08292,
    },
    {
        name: 'Carterton',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.759712,
        longitude: -1.593124,
    },
    {
        name: 'Stapleford',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.9286389,
        longitude: -1.272719,
    },
    {
        name: 'Kilwinning',
        ceremonialArea: 'North Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.65591999999999,
        longitude: -4.703117000000001,
    },
    {
        name: 'Haslingden',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.7008264,
        longitude: -2.3261002,
    },
    {
        name: 'Hartley',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.385147,
        longitude: 0.3049830000000001,
    },
    {
        name: 'Maltby',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.422615,
        longitude: -1.201148,
    },
    {
        name: 'Penzance',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.118798,
        longitude: -5.537592,
    },
    {
        name: 'Uckfield',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.966414,
        longitude: 0.09591300000000001,
    },
    {
        name: 'Wimborne Minster',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8004646,
        longitude: -1.9830004,
    },
    {
        name: 'South Woodham',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.6465,
        longitude: 0.614665,
    },
    {
        name: 'Carmarthen',
        ceremonialArea: 'Dyfed',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.85762,
        longitude: -4.312131,
    },
    {
        name: 'Ripon',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.1361346,
        longitude: -1.5237756,
    },
    {
        name: 'Whickham',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.944116,
        longitude: -1.674552,
    },
    {
        name: 'Elland',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.68350299999999,
        longitude: -1.844929,
    },
    {
        name: 'Bourne',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.76859899999999,
        longitude: -0.378462,
    },
    {
        name: 'Penicuik',
        ceremonialArea: 'Midlothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.830932,
        longitude: -3.224533,
    },
    {
        name: 'Johnstone',
        ceremonialArea: 'Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.837324,
        longitude: -4.513915,
    },
    {
        name: 'Nailsea',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.43175600000001,
        longitude: -2.756287,
    },
    {
        name: 'Clitheroe',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.871098,
        longitude: -2.393083,
    },
    {
        name: 'Conisborough',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.4805944,
        longitude: -1.2263372,
    },
    {
        name: 'South Normanton',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.1081689,
        longitude: -1.340708,
    },
    {
        name: 'Saltash',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.40920999999999,
        longitude: -4.21643,
    },
    {
        name: 'South Hayling',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.787522,
        longitude: -0.977454,
    },
    {
        name: 'Lymington',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.758531,
        longitude: -1.5419099,
    },
    {
        name: 'Baildon',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.852716,
        longitude: -1.768142,
    },
    {
        name: 'Viewpark',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.8272956,
        longitude: -4.0580509,
    },
    {
        name: 'Bodmin',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.4714961,
        longitude: -4.7188629,
    },
    {
        name: 'Matlock',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.137156,
        longitude: -1.551774,
    },
    {
        name: 'Tadley',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.343801,
        longitude: -1.132169,
    },
    {
        name: 'Teignmouth',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.54703300000001,
        longitude: -3.496688,
    },
    {
        name: 'Porthcawl',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.47896799999999,
        longitude: -3.705163,
    },
    {
        name: 'Knaresborough',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.0074396,
        longitude: -1.4645728,
    },
    {
        name: 'Dursley',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.678768,
        longitude: -2.350518,
    },
    {
        name: 'Mexborough',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.493099,
        longitude: -1.280452,
    },
    {
        name: 'Taverham',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.678335,
        longitude: 1.194086,
    },
    {
        name: 'Erskine',
        ceremonialArea: 'Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.909243,
        longitude: -4.462418,
    },
    {
        name: 'Penrith',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.664097,
        longitude: -2.752708,
    },
    {
        name: 'Broxburn',
        ceremonialArea: 'West Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.934161,
        longitude: -3.467681,
    },
    {
        name: 'Hythe, Kent',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.071739,
        longitude: 1.081937,
    },
    {
        name: 'Staveley',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.377897,
        longitude: -2.817677,
    },
    {
        name: 'Mildenhall',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.347568,
        longitude: 0.5156094999999999,
    },
    {
        name: 'Llandudno',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.324061,
        longitude: -3.827609,
    },
    {
        name: 'Dundonald',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.5930972,
        longitude: -5.794609599999999,
    },
    {
        name: 'Frampton Cotterell',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.536335,
        longitude: -2.480503,
    },
    {
        name: 'Kidlington',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.823398,
        longitude: -1.290459,
    },
    {
        name: 'Wymondham',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.569354,
        longitude: 1.115305,
    },
    {
        name: 'Petersfield',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.007591,
        longitude: -0.93899,
    },
    {
        name: 'Neston',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.290584,
        longitude: -3.069102,
    },
    {
        name: 'Swallownest',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3630141,
        longitude: -1.3211043,
    },
    {
        name: 'Poynton',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.348654,
        longitude: -2.121572,
    },
    {
        name: 'Skipton',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.9628495,
        longitude: -2.0162787,
    },
    {
        name: 'Ilkley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.925486,
        longitude: -1.822817,
    },
    {
        name: "Bishop's Cleeve",
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.947366,
        longitude: -2.060942,
    },
    {
        name: 'Haverfordwest',
        ceremonialArea: 'Dyfed',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.800475,
        longitude: -4.971318999999999,
    },
    {
        name: 'Bordon',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.115613,
        longitude: -0.861611,
    },
    {
        name: 'Newtownbreda',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.5473683,
        longitude: -5.9195825,
    },
    {
        name: 'Tredegar',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.772619,
        longitude: -3.246775,
    },
    {
        name: 'Risca',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.611827,
        longitude: -3.1018,
    },
    {
        name: 'West Kirby',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3723866,
        longitude: -3.1842948,
    },
    {
        name: 'Cottingham',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.782081,
        longitude: -0.413978,
    },
    {
        name: 'Blaydon',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.96122339999999,
        longitude: -1.7181963,
    },
    {
        name: 'Newport Pagnell',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.084585,
        longitude: -0.734583,
    },
    {
        name: 'Marlow',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.5719443,
        longitude: -0.7769421999999999,
    },
    {
        name: 'Buckingham',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.999326,
        longitude: -0.987645,
    },
    {
        name: 'Borrowstounness',
        ceremonialArea: 'Falkirk',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.0130357,
        longitude: -3.6035314,
    },
    {
        name: 'Garforth',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.792149,
        longitude: -1.389877,
    },
    {
        name: 'Armagh',
        ceremonialArea: 'County Armagh',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.3502798,
        longitude: -6.652792,
    },
    {
        name: 'Larkhall',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.737744,
        longitude: -3.971699,
    },
    {
        name: 'Prestwick',
        ceremonialArea: 'South Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.495587,
        longitude: -4.6142139,
    },
    {
        name: 'Troon',
        ceremonialArea: 'South Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.541332,
        longitude: -4.659948,
    },
    {
        name: 'Morpeth',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.16875,
        longitude: -1.687493,
    },
    {
        name: 'Flint',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.248236,
        longitude: -3.13577,
    },
    {
        name: 'Beaconsfield',
        ceremonialArea: 'Buckinghamshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.602396,
        longitude: -0.6442409,
    },
    {
        name: 'Chertsey',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.386491,
        longitude: -0.509456,
    },
    {
        name: 'Port Glasgow',
        ceremonialArea: 'Inverclyde',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.932291,
        longitude: -4.679806999999999,
    },
    {
        name: 'Yateley',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3388484,
        longitude: -0.8288698999999999,
    },
    {
        name: 'Porth',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.613554,
        longitude: -3.407184,
    },
    {
        name: 'Armthorpe',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5352099,
        longitude: -1.0522282,
    },
    {
        name: 'Cowes',
        ceremonialArea: 'Isle of Wight',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.76278500000001,
        longitude: -1.300533,
    },
    {
        name: 'Knottingley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.707105,
        longitude: -1.2437419,
    },
    {
        name: 'Hessle',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.723805,
        longitude: -0.434858,
    },
    {
        name: 'Ashtead',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.31068399999999,
        longitude: -0.296968,
    },
    {
        name: 'Alloa',
        ceremonialArea: 'Clackmannanshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.114073,
        longitude: -3.791896,
    },
    {
        name: 'Market Deeping',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6795904,
        longitude: -0.3216218,
    },
    {
        name: 'Pyle',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.528762,
        longitude: -3.69316,
    },
    {
        name: 'Dunscroft',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5771196,
        longitude: -1.0200097,
    },
    {
        name: 'Dungannon',
        ceremonialArea: 'County Tyrone',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.5082725,
        longitude: -6.7669352,
    },
    {
        name: 'Ringwood',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.844949,
        longitude: -1.788817,
    },
    {
        name: 'Otley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.905825,
        longitude: -1.691786,
    },
    {
        name: 'Crowthorne',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.36690100000001,
        longitude: -0.795353,
    },
    {
        name: 'Houghton-le-Spring',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.841016,
        longitude: -1.468691,
    },
    {
        name: 'Forfar',
        ceremonialArea: 'Angus',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.643558,
        longitude: -2.889062,
    },
    {
        name: 'Beccles',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.459333,
        longitude: 1.566053,
    },
    {
        name: 'Brackley',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.027411,
        longitude: -1.143152,
    },
    {
        name: 'Middlewich',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.19256499999999,
        longitude: -2.443833,
    },
    {
        name: 'Church Village',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.5661429,
        longitude: -3.317116,
    },
    {
        name: 'Newhaven',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.79307000000001,
        longitude: 0.045574,
    },
    {
        name: 'Innsworth',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.889429,
        longitude: -2.204576,
    },
    {
        name: 'Shepshed',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.7701009,
        longitude: -1.292427,
    },
    {
        name: 'Milford Haven',
        ceremonialArea: 'Dyfed',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.714306,
        longitude: -5.042697,
    },
    {
        name: 'Bacup',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.707167,
        longitude: -2.201259,
    },
    {
        name: 'Syston',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6992685,
        longitude: -1.0729984,
    },
    {
        name: 'Cheadle, Greater Manchester',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.394361,
        longitude: -2.214115,
    },
    {
        name: 'Ashby-de-la-Zouch',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.749212,
        longitude: -1.468618,
    },
    {
        name: 'Enniskillen',
        ceremonialArea: 'County Fermanagh',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.34382429999999,
        longitude: -7.6315336,
    },
    {
        name: 'Chard',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8697919,
        longitude: -2.963316,
    },
    {
        name: 'Rosyth',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.03646999999999,
        longitude: -3.423064,
    },
    {
        name: 'Narborough',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5735743,
        longitude: -1.208212,
    },
    {
        name: 'Verwood',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8789783,
        longitude: -1.8654487,
    },
    {
        name: 'Hawick',
        ceremonialArea: 'Scottish Borders',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.42706,
        longitude: -2.780914,
    },
    {
        name: 'Hurstpierpoint',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.934439,
        longitude: -0.179902,
    },
    {
        name: 'Uppermill',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.551691,
        longitude: -2.008277,
    },
    {
        name: 'Street',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.124679,
        longitude: -2.742919,
    },
    {
        name: 'Corsham',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.43144299999999,
        longitude: -2.189674,
    },
    {
        name: 'Helensburgh',
        ceremonialArea: 'Argyll and Bute',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.002318,
        longitude: -4.734014,
    },
    {
        name: 'Inverurie',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.28347400000001,
        longitude: -2.373995,
    },
    {
        name: 'Wombourne',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5335279,
        longitude: -2.1873269,
    },
    {
        name: 'Oxted',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.255714,
        longitude: -0.000003,
    },
    {
        name: 'Blackfield',
        ceremonialArea: 'Hampshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.81806760000001,
        longitude: -1.3726131,
    },
    {
        name: 'Bridport',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.7335769,
        longitude: -2.758301,
    },
    {
        name: 'Llantrisant',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.5429188,
        longitude: -3.3749525,
    },
    {
        name: 'Mayfield',
        ceremonialArea: 'Midlothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 53.007782,
        longitude: -1.769142,
    },
    {
        name: 'Alsager',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.095402,
        longitude: -2.304657,
    },
    {
        name: 'Midsomer Norton',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.285199,
        longitude: -2.485936,
    },
    {
        name: 'Birstall',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6762588,
        longitude: -1.1194045,
    },
    {
        name: 'Chorleywood',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.655632,
        longitude: -0.5161829,
    },
    {
        name: 'Standish',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.586808,
        longitude: -2.6659639,
    },
    {
        name: 'Berwick-upon-Tweed',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.770242,
        longitude: -2.005395,
    },
    {
        name: 'Whetstone',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.6300102,
        longitude: -0.1747954,
    },
    {
        name: 'Tilbury',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.463024,
        longitude: 0.360498,
    },
    {
        name: 'Abergavenny',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.825366,
        longitude: -3.019423,
    },
    {
        name: 'Dalkieth',
        ceremonialArea: 'Midlothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.89311799999999,
        longitude: -3.066606,
    },
    {
        name: 'Haslemere',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.090856,
        longitude: -0.7133729999999999,
    },
    {
        name: 'Rhosllanerchrugog',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.013057,
        longitude: -3.057852,
    },
    {
        name: 'Saltdean',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.80107,
        longitude: -0.041899,
    },
    {
        name: 'Carluke',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.735435,
        longitude: -3.836552,
    },
    {
        name: 'Clay Cross',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.163795,
        longitude: -1.414784,
    },
    {
        name: 'New Rossington',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.47670400000001,
        longitude: -1.059747,
    },
    {
        name: 'Knutsford',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3005939,
        longitude: -2.371833,
    },
    {
        name: 'Sheerness',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.44011,
        longitude: 0.764158,
    },
    {
        name: 'Linlithgow',
        ceremonialArea: 'West Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.9716266,
        longitude: -3.6025847,
    },
    {
        name: 'Mountsorrel',
        ceremonialArea: 'Leicesetershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.725644,
        longitude: -1.140136,
    },
    {
        name: 'Rhondda',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.64902069999999,
        longitude: -3.4288692,
    },
    {
        name: 'Brough',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.729988,
        longitude: -0.5732149999999999,
    },
    {
        name: 'Newport, Shropshire',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.584151,
        longitude: -2.997664,
    },
    {
        name: 'Fraserburgh',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.693352,
        longitude: -2.007631,
    },
    {
        name: 'Sidmouth',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.67865,
        longitude: -3.23756,
    },
    {
        name: 'Strabane',
        ceremonialArea: 'County Tyrone',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.8273865,
        longitude: -7.4633103,
    },
    {
        name: 'Uttoxeter',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.89811599999999,
        longitude: -1.865801,
    },
    {
        name: 'Driffield',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.005996,
        longitude: -0.443377,
    },
    {
        name: 'Whitby',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.486335,
        longitude: -0.613347,
    },
    {
        name: 'Galashiels',
        ceremonialArea: 'Scottish Borders',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.623728,
        longitude: -2.814449,
    },
    {
        name: 'Ince-in-Makerfield',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.536413,
        longitude: -2.619493,
    },
    {
        name: 'Whittlesley',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5581179,
        longitude: -0.1300956,
    },
    {
        name: 'Milngavie',
        ceremonialArea: 'East Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.943304,
        longitude: -4.316987,
    },
    {
        name: 'Lees',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.5388363,
        longitude: -2.0727062,
    },
    {
        name: 'Stocksbridge',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.482769,
        longitude: -1.593719,
    },
    {
        name: 'Bridgnorth',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.53445499999999,
        longitude: -2.424549,
    },
    {
        name: 'Dawlish',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.582285,
        longitude: -3.464402,
    },
    {
        name: 'Pontardawe',
        ceremonialArea: 'West Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.72043499999999,
        longitude: -3.853214999999999,
    },
    {
        name: 'Royal Wootton Bassett',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.54139499999999,
        longitude: -1.902318,
    },
    {
        name: 'Cudworth',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.577872,
        longitude: -1.415027,
    },
    {
        name: 'Kirkham',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.78216399999999,
        longitude: -2.871765,
    },
    {
        name: 'Catterick Garrison',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.3776861,
        longitude: -1.7228174,
    },
    {
        name: 'Heckmondwike',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.706976,
        longitude: -1.670679,
    },
    {
        name: 'Saltcoats',
        ceremonialArea: 'North Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.637652,
        longitude: -4.7834899,
    },
    {
        name: 'New Mills',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.3678916,
        longitude: -2.0047714,
    },
    {
        name: 'Mablethorpe',
        ceremonialArea: 'Lincolnshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.34088200000001,
        longitude: 0.261071,
    },
    {
        name: 'Little Lever',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.56398799999999,
        longitude: -2.377596,
    },
    {
        name: 'Armadale',
        ceremonialArea: 'West Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.897742,
        longitude: -3.702162,
    },
    {
        name: 'Guiseley',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.877118,
        longitude: -1.706645,
    },
    {
        name: 'North Walsham',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.822699,
        longitude: 1.3859729,
    },
    {
        name: 'Tavistock',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.5511229,
        longitude: -4.141654,
    },
    {
        name: 'Wellington',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.978564,
        longitude: -3.2244989,
    },
    {
        name: 'Llandudno Junction',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.286781,
        longitude: -3.807032,
    },
    {
        name: 'Chepstow',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.641856,
        longitude: -2.673804,
    },
    {
        name: 'Walton-on-the-Naze',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.848186,
        longitude: 1.267736,
    },
    {
        name: 'Hetton-le-Hole',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.827971,
        longitude: -1.454298,
    },
    {
        name: 'Blandford Forum',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.856908,
        longitude: -2.165353,
    },
    {
        name: 'Giffnock',
        ceremonialArea: 'East Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.800047,
        longitude: -4.295033,
    },
    {
        name: 'Westhill',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.15562300000001,
        longitude: -2.277129,
    },
    {
        name: 'Tring',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.79607799999999,
        longitude: -0.655879,
    },
    {
        name: 'Market Drayton',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.903552,
        longitude: -2.483485,
    },
    {
        name: 'Littleborough',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.646141,
        longitude: -2.093367,
    },
    {
        name: 'Tranent',
        ceremonialArea: 'East Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.94450999999999,
        longitude: -2.954526,
    },
    {
        name: 'Halstead',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.945073,
        longitude: 0.6390319999999999,
    },
    {
        name: 'Todmorden',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.716344,
        longitude: -2.0987969,
    },
    {
        name: 'Montrose',
        ceremonialArea: 'Angus',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.706922,
        longitude: -2.466115,
    },
    {
        name: 'Limavady',
        ceremonialArea: 'County Londonderry',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 55.0454563,
        longitude: -6.9336758,
    },
    {
        name: 'Minehead',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.20428,
        longitude: -3.481152,
    },
    {
        name: 'Bolsover',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.231044,
        longitude: -1.2897209,
    },
    {
        name: 'Thorne',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.6104213,
        longitude: -0.9614398000000001,
    },
    {
        name: 'Lymm',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.381724,
        longitude: -2.480044,
    },
    {
        name: 'Downham Market',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.606612,
        longitude: 0.385472,
    },
    {
        name: 'Thornbury',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.608306,
        longitude: -2.525153,
    },
    {
        name: 'Cleckheaton',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.723048,
        longitude: -1.707525,
    },
    {
        name: 'Sandown',
        ceremonialArea: 'Isle of Wight',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.659079,
        longitude: -1.149397,
    },
    {
        name: 'Southwick',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8353139,
        longitude: -0.238234,
    },
    {
        name: 'Sarn Aberkenfig',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.5384298,
        longitude: -3.5879025,
    },
    {
        name: 'Bargoed',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.684912,
        longitude: -3.229699,
    },
    {
        name: 'Helston',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.101593,
        longitude: -5.274996,
    },
    {
        name: 'Sandy',
        ceremonialArea: 'Bedfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.128118,
        longitude: -0.286788,
    },
    {
        name: 'Haxby',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.01638999999999,
        longitude: -1.0621415,
    },
    {
        name: 'Shirebrook',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.2019189,
        longitude: -1.217961,
    },
    {
        name: 'Ivybridge',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.390202,
        longitude: -3.920431,
    },
    {
        name: 'Kimberley',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.994062,
        longitude: -1.253579,
    },
    {
        name: 'Holyhead',
        ceremonialArea: 'Gwynedd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.309441,
        longitude: -4.633038,
    },
    {
        name: 'Codsall',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.628089,
        longitude: -2.192079,
    },
    {
        name: 'Barton-upon-Humber',
        ceremonialArea: 'Lincolnshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.686675,
        longitude: -0.443368,
    },
    {
        name: 'Featherstone',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.674814,
        longitude: -1.356949,
    },
    {
        name: 'Cowdenbeath',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.114407,
        longitude: -3.341848,
    },
    {
        name: 'Henley-on-Thames',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.535764,
        longitude: -0.902894,
    },
    {
        name: 'Woodbridge',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.095481,
        longitude: 1.312571,
    },
    {
        name: 'Caldicot',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.591518,
        longitude: -2.750868,
    },
    {
        name: 'Cookstown',
        ceremonialArea: 'County Tyrone',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.6418158,
        longitude: -6.744389399999999,
    },
    {
        name: 'Gillingham, Dorset',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.386322,
        longitude: 0.551438,
    },
    {
        name: 'Thame',
        ceremonialArea: 'Oxfordshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.746997,
        longitude: -0.9741879999999999,
    },
    {
        name: 'Snodland',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3313,
        longitude: 0.445068,
    },
    {
        name: 'Attleborough',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.518064,
        longitude: 1.015527,
    },
    {
        name: 'Honiton',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.799468,
        longitude: -3.188683,
    },
    {
        name: 'Desbrorough',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.441465,
        longitude: -0.822557,
    },
    {
        name: 'Leominster',
        ceremonialArea: 'Herefordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.2257,
        longitude: -2.742772,
    },
    {
        name: 'Oswaldtwistle',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.742244,
        longitude: -2.406538,
    },
    {
        name: 'Southborough',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.1600204,
        longitude: 0.2576326,
    },
    {
        name: 'Hexham',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.972665,
        longitude: -2.1121439,
    },
    {
        name: 'Carnoustie',
        ceremonialArea: 'Angus',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.502565,
        longitude: -2.70325,
    },
    {
        name: 'Ross-on-Wye',
        ceremonialArea: 'Herefordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.91445,
        longitude: -2.582446,
    },
    {
        name: 'Ulverston',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.195138,
        longitude: -3.09267,
    },
    {
        name: 'Holywood',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.63444699999999,
        longitude: -5.846255699999999,
    },
    {
        name: 'Newtown',
        ceremonialArea: 'Powys',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 52.51211199999999,
        longitude: -3.313106,
    },
    {
        name: 'Atherstone',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.944768,
        longitude: -2.878155,
    },
    {
        name: 'Tidworth',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.24144889999999,
        longitude: -1.6644823,
    },
    {
        name: 'Cinderford',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.825303,
        longitude: -2.5009579,
    },
    {
        name: 'Mountain Ash',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.681615,
        longitude: -3.373141,
    },
    {
        name: 'Kearsley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.539253,
        longitude: -2.374366,
    },
    {
        name: 'Largs',
        ceremonialArea: 'North Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.79333500000001,
        longitude: -4.867278000000001,
    },
    {
        name: 'Cheadle, Staffordshire',
        ceremonialArea: 'Staffordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.394361,
        longitude: -2.214115,
    },
    {
        name: 'Epping',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.700328,
        longitude: 0.108655,
    },
    {
        name: 'Stonehaven',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.96366099999999,
        longitude: -2.209288,
    },
    {
        name: 'Wetherby',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.92705600000001,
        longitude: -1.384816,
    },
    {
        name: 'Amesbury',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.1679201,
        longitude: -1.7629783,
    },
    {
        name: 'Oakham',
        ceremonialArea: 'Rutland',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6695629,
        longitude: -0.726625,
    },
    {
        name: 'Mossley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.514681,
        longitude: -2.038679,
    },
    {
        name: 'Ilfracombe',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.205163,
        longitude: -4.126761999999999,
    },
    {
        name: 'Immingham',
        ceremonialArea: 'Lincolnshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.614012,
        longitude: -0.215913,
    },
    {
        name: 'Larbert',
        ceremonialArea: 'Falkirk',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.0196754,
        longitude: -3.8360606,
    },
    {
        name: 'Prudhoe',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.962199,
        longitude: -1.84723,
    },
    {
        name: 'Wells',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.21049860000001,
        longitude: -2.646776,
    },
    {
        name: 'Chigwell',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.62628100000001,
        longitude: 0.080647,
    },
    {
        name: 'Chatteris',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.45610199999999,
        longitude: 0.054012,
    },
    {
        name: 'South Elmsall',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.59673,
        longitude: -1.2831,
    },
    {
        name: 'Banstead',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3221909,
        longitude: -0.205046,
    },
    {
        name: 'Downpatrick',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.32875139999999,
        longitude: -5.715692199999999,
    },
    {
        name: 'Whitburn',
        ceremonialArea: 'West Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.867674,
        longitude: -3.682519,
    },
    {
        name: 'Kingsteignton',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.549083,
        longitude: -3.598421,
    },
    {
        name: 'Great Harwood',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.781914,
        longitude: -2.400249,
    },
    {
        name: 'Hoylake',
        ceremonialArea: 'Merseyside',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.393238,
        longitude: -3.1762801,
    },
    {
        name: 'Annfield Plain',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.8551431,
        longitude: -1.742843,
    },
    {
        name: 'Diss',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.37649099999999,
        longitude: 1.108394,
    },
    {
        name: 'Methill',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.184843,
        longitude: -3.0164019,
    },
    {
        name: 'Northam',
        ceremonialArea: 'Devon',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.039809,
        longitude: -4.213152,
    },
    {
        name: 'Abertillery',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.732161,
        longitude: -3.135369,
    },
    {
        name: 'Cleveleys',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.8808253,
        longitude: -3.0389598,
    },
    {
        name: 'Catshill',
        ceremonialArea: 'Worcestershire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.36062099999999,
        longitude: -2.064573,
    },
    {
        name: 'Penistone',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.526141,
        longitude: -1.6255329,
    },
    {
        name: 'Shepton Mallet',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.1909,
        longitude: -2.547886,
    },
    {
        name: 'Androssan',
        ceremonialArea: 'North Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.64388899999999,
        longitude: -4.81182,
    },
    {
        name: 'Baldock',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.9895039,
        longitude: -0.189128,
    },
    {
        name: 'Swanage',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.608277,
        longitude: -1.960769,
    },
    {
        name: 'New Ollerton',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.208414,
        longitude: -1.012229,
    },
    {
        name: 'Platts Bridge',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.52363099999999,
        longitude: -2.598046,
    },
    {
        name: 'Royston, South Yorkshire',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.048142,
        longitude: -0.024066,
    },
    {
        name: 'Sawbridgeworth',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.81554,
        longitude: 0.14721,
    },
    {
        name: 'Waltham Cross',
        ceremonialArea: 'Hertfordshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.68584389999999,
        longitude: -0.0330964,
    },
    {
        name: 'Polegate',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.824012,
        longitude: 0.243813,
    },
    {
        name: 'Ystradgynlais',
        ceremonialArea: 'Powys',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.779621,
        longitude: -3.753603,
    },
    {
        name: 'Thirsk',
        ceremonialArea: 'North Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.233849,
        longitude: -1.341377,
    },
    {
        name: 'Ludlow',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.367749,
        longitude: -2.7139129,
    },
    {
        name: 'Lutterworth',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.45599499999999,
        longitude: -1.199159,
    },
    {
        name: 'Horbury',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.661066,
        longitude: -1.552155,
    },
    {
        name: 'Monmouth',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.8116533,
        longitude: -2.7163045,
    },
    {
        name: 'Selsey',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.730991,
        longitude: -0.793704,
    },
    {
        name: 'Ponteland',
        ceremonialArea: 'Northumberland',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.049871,
        longitude: -1.739844,
    },
    {
        name: 'Prestonpans',
        ceremonialArea: 'East Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.95953799999999,
        longitude: -2.984467,
    },
    {
        name: 'St Blazey',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.36304699999999,
        longitude: -4.717379,
    },
    {
        name: 'Ballymoney',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 55.0704888,
        longitude: -6.5173708,
    },
    {
        name: 'Gourock',
        ceremonialArea: 'Inverclyde',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.9591984,
        longitude: -4.8168799,
    },
    {
        name: 'North Ascot',
        ceremonialArea: 'Berkshire',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.417305,
        longitude: -0.6964290000000001,
    },
    {
        name: 'Penryn',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.169173,
        longitude: -5.107088000000001,
    },
    {
        name: 'Stranraer',
        ceremonialArea: 'Dumfries and Galloway',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 54.903367,
        longitude: -5.024055,
    },
    {
        name: 'Chadwell St Mary',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.481971,
        longitude: 0.369876,
    },
    {
        name: 'Brierfield',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.82160400000001,
        longitude: -2.2367309,
    },
    {
        name: 'Barnoldswick',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.91301499999999,
        longitude: -2.186918,
    },
    {
        name: 'Milnrow',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.61071099999999,
        longitude: -2.11657,
    },
    {
        name: 'Bingham',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.951088,
        longitude: -0.9561440000000001,
    },
    {
        name: 'Ellon',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.364597,
        longitude: -2.072972,
    },
    {
        name: 'Cranleigh',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.141073,
        longitude: -0.483985,
    },
    {
        name: 'Soham',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.335212,
        longitude: 0.3375079,
    },
    {
        name: 'Westergate',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.83817,
        longitude: -0.668412,
    },
    {
        name: 'Mold',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.167203,
        longitude: -3.141903,
    },
    {
        name: 'Radstock',
        ceremonialArea: 'Somerset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.29201699999999,
        longitude: -2.446092,
    },
    {
        name: 'Forres',
        ceremonialArea: 'Moray',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.60979099999999,
        longitude: -3.61998,
    },
    {
        name: 'Kilsyth',
        ceremonialArea: 'North Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.979788,
        longitude: -4.056933,
    },
    {
        name: 'Cambourne',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.21776389999999,
        longitude: -0.0662097,
    },
    {
        name: 'Dalgety Bay',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.03490799999999,
        longitude: -3.35056,
    },
    {
        name: 'Whitchurch',
        ceremonialArea: 'Shropshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.22976999999999,
        longitude: -1.335304,
    },
    {
        name: 'Brandon, County Durham',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.7552713,
        longitude: -1.6407615,
    },
    {
        name: 'Nairn',
        ceremonialArea: 'Highland',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.58642200000001,
        longitude: -3.868475,
    },
    {
        name: 'New Romney',
        ceremonialArea: 'Kent',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.985117,
        longitude: 0.9394730000000001,
    },
    {
        name: 'Polesworth',
        ceremonialArea: 'Warwickshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.620606,
        longitude: -1.6155139,
    },
    {
        name: 'Woodingdean',
        ceremonialArea: 'East Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.8368286,
        longitude: -0.0752813,
    },
    {
        name: 'Ballyclare',
        ceremonialArea: 'County Antrim',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.75291439999999,
        longitude: -6.001522100000001,
    },
    {
        name: 'Holywell',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.27600899999999,
        longitude: -3.225103,
    },
    {
        name: 'Sandiacre',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.926499,
        longitude: -1.288894,
    },
    {
        name: 'Shildon',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.63341699999999,
        longitude: -1.654915,
    },
    {
        name: 'Adlington',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.611055,
        longitude: -2.606365,
    },
    {
        name: 'Clarkston',
        ceremonialArea: 'East Renfrewshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.784536,
        longitude: -4.275812,
    },
    {
        name: 'North Walney',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.1043016,
        longitude: -3.2518244,
    },
    {
        name: 'Caernarfon',
        ceremonialArea: 'Gwynedd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.139551,
        longitude: -4.273911,
    },
    {
        name: 'Sherborne',
        ceremonialArea: 'Dorset',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.947822,
        longitude: -2.5144829,
    },
    {
        name: 'Stenhousemuir',
        ceremonialArea: 'Falkirk',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.02707040000001,
        longitude: -3.8142224,
    },
    {
        name: 'Kippax',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.766869,
        longitude: -1.370976,
    },
    {
        name: 'Towcester',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.135107,
        longitude: -0.9896879999999999,
    },
    {
        name: 'Pembroke Dock',
        ceremonialArea: 'Dyfed',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.691894,
        longitude: -4.943763,
    },
    {
        name: 'Bulford Camp',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.1930686,
        longitude: -1.7336429,
    },
    {
        name: 'Warlingham',
        ceremonialArea: 'Surrey',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.3093239,
        longitude: -0.055901,
    },
    {
        name: 'St Ives, Cornwall',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.20838579999999,
        longitude: -5.490886400000001,
    },
    {
        name: 'Newbridge',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.665206,
        longitude: -3.144116,
    },
    {
        name: 'Bradford on Avon',
        ceremonialArea: 'Wiltshire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.345178,
        longitude: -2.2525019,
    },
    {
        name: 'Tonyrefail',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.583768,
        longitude: -3.428319,
    },
    {
        name: 'Crook',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.715131,
        longitude: -1.743545,
    },
    {
        name: 'Kinmel Bay',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.310802,
        longitude: -3.519355,
    },
    {
        name: 'Maryport',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.714441,
        longitude: -3.49494,
    },
    {
        name: 'Brandon, Suffolk',
        ceremonialArea: 'Suffolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.7552713,
        longitude: -1.6407615,
    },
    {
        name: 'Wideopen',
        ceremonialArea: 'Tyne and Wear',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 55.051556,
        longitude: -1.627601,
    },
    {
        name: 'Burton Latimer',
        ceremonialArea: 'Northamptonshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.365906,
        longitude: -0.6782079999999999,
    },
    {
        name: 'Liskeard',
        ceremonialArea: 'Cornwall',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.45552,
        longitude: -4.464719,
    },
    {
        name: 'Ferryhill',
        ceremonialArea: 'County Durham',
        region: 'North East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.68803399999999,
        longitude: -1.553164,
    },
    {
        name: 'Watton',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.570848,
        longitude: 0.828023,
    },
    {
        name: 'Yaxley',
        ceremonialArea: 'Cambridgeshire',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5185519,
        longitude: -0.2560319,
    },
    {
        name: 'Dunblane',
        ceremonialArea: 'Stirling',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.183877,
        longitude: -3.967449,
    },
    {
        name: 'Killamarsh',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.320515,
        longitude: -1.313704,
    },
    {
        name: 'Market Warsop',
        ceremonialArea: 'Nottinghamshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.2043315,
        longitude: -1.1548923,
    },
    {
        name: 'Cockermouth',
        ceremonialArea: 'Cumbria',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 54.663261,
        longitude: -3.367985,
    },
    {
        name: 'Hemsworth',
        ceremonialArea: 'West Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.61542799999999,
        longitude: -1.352693,
    },
    {
        name: 'South Queensferry',
        ceremonialArea: 'Edinburgh',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.99000299999999,
        longitude: -3.399045,
    },
    {
        name: 'Pencoed',
        ceremonialArea: 'Mid Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.52108699999999,
        longitude: -3.49837,
    },
    {
        name: 'North Wingfield',
        ceremonialArea: 'Derbyshire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.1822159,
        longitude: -1.388955,
    },
    {
        name: 'Abergele',
        ceremonialArea: 'Clwyd',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 53.284355,
        longitude: -3.581405,
    },
    {
        name: 'Pontarddulais',
        ceremonialArea: 'West Glamorgan',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.712266,
        longitude: -4.037071,
    },
    {
        name: 'Sileby',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.729949,
        longitude: -1.110268,
    },
    {
        name: 'Pontllanfraith',
        ceremonialArea: 'Gwent',
        region: 'Wales',
        flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        latitude: 51.656335,
        longitude: -3.182134,
    },
    {
        name: 'Worsbrough',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.528409,
        longitude: -1.461325,
    },
    {
        name: 'Stevenston',
        ceremonialArea: 'North Ayrshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.641817,
        longitude: -4.760409999999999,
    },
    {
        name: 'Shanklin',
        ceremonialArea: 'Isle of Wight',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 50.63467499999999,
        longitude: -1.175199,
    },
    {
        name: 'Frodsham',
        ceremonialArea: 'Cheshire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.2967045,
        longitude: -2.7245643,
    },
    {
        name: 'Tiptree',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.812119,
        longitude: 0.74656,
    },
    {
        name: 'Lydney',
        ceremonialArea: 'Gloucestershire',
        region: 'South West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.72913,
        longitude: -2.530504,
    },
    {
        name: 'Southwater',
        ceremonialArea: 'West Sussex',
        region: 'South East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.022149,
        longitude: -0.353682,
    },
    {
        name: 'Ledbury',
        ceremonialArea: 'Herefordshire',
        region: 'West Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.033882,
        longitude: -2.423574,
    },
    {
        name: 'Worsley',
        ceremonialArea: 'Greater Manchester',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.50754,
        longitude: -2.391199,
    },
    {
        name: 'Haddington',
        ceremonialArea: 'East Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.95867399999999,
        longitude: -2.774864,
    },
    {
        name: 'Aveley',
        ceremonialArea: 'Essex',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 51.499018,
        longitude: 0.2519159,
    },
    {
        name: 'Portlethen',
        ceremonialArea: 'Aberdeenshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 57.061681,
        longitude: -2.129468,
    },
    {
        name: 'Comber',
        ceremonialArea: 'County Down',
        region: 'Northern Ireland',
        flag: '🇬🇧',
        latitude: 54.5504547,
        longitude: -5.7456751,
    },
    {
        name: 'Bonhill',
        ceremonialArea: 'West Dunbartonshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.9836153,
        longitude: -4.5711001,
    },
    {
        name: 'Lanark',
        ceremonialArea: 'South Lanarkshire',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.673865,
        longitude: -3.782138,
    },
    {
        name: 'Leven',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.19632,
        longitude: -2.996578,
    },
    {
        name: 'Dunbar',
        ceremonialArea: 'East Lothian',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.002087,
        longitude: -2.516737,
    },
    {
        name: 'Blairgowrie',
        ceremonialArea: 'Perth and Kinross',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.5894057,
        longitude: -3.3378318,
    },
    {
        name: 'Cupar',
        ceremonialArea: 'Fife',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 56.320235,
        longitude: -3.010137,
    },
    {
        name: 'Longton',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.9919179,
        longitude: -2.1312225,
    },
    {
        name: 'Thurnscoe',
        ceremonialArea: 'South Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.546071,
        longitude: -1.315757,
    },
    {
        name: 'Pocklington',
        ceremonialArea: 'East Riding of Yorkshire',
        region: 'Yorkshire Humber',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.9300565,
        longitude: -0.7794059,
    },
    {
        name: 'Burscough',
        ceremonialArea: 'Lancashire',
        region: 'North West',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 53.591359,
        longitude: -2.852196,
    },
    {
        name: 'Broughton Astley',
        ceremonialArea: 'Leicestershire',
        region: 'East Midlands',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.5252253,
        longitude: -1.2172262,
    },
    {
        name: 'Peebles',
        ceremonialArea: 'Scottish Borders',
        region: 'Scotland',
        flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        latitude: 55.653071,
        longitude: -3.193642,
    },
    {
        name: 'Caister-on-Sea',
        ceremonialArea: 'Norfolk',
        region: 'East',
        flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        latitude: 52.6486469,
        longitude: 1.724422,
    },
];

export type SearchRadius = {
    value: number;
    unit: 'miles' | 'kilometers';
};

export const DEFAULT_RADIUS: SearchRadius = {
    value: 15,
    unit: 'miles',
};

export type LocationFilter =
    | {
          known_location: string;
          radius: SearchRadius;
      }
    | {
          query: string;
          radius: SearchRadius;
      };

export const convertRadiusToMeters = (radius: SearchRadius): number => {
    const { value, unit } = radius;
    switch (unit) {
        case 'miles':
            return value * 1609;
        case 'kilometers':
            return value * 1000;
    }
};

const queryLocationToGeoCoordinates = async (
    query: string
): Promise<{ latitude: number; longitude: number } | null> => {
    const queryWithCountry = `${query}, United Kingdom`;
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/search/geo-query?query=${encodeURIComponent(queryWithCountry)}`,
        { credentials: 'include' }
    );
    if (response.ok === false) {
        if (response.headers.get('content-type') === 'application/problem+json') {
            const problemDetails = await response.json();
            throw CademyError.fromObject(problemDetails);
        }
        const responseText = await response.text();
        throw new Error(responseText);
    }
    const body = await response.json();
    return body;
};

export const findLocationByName = (name: string): LocationWithGeoCoordinates | undefined => {
    return locationsWithGeoCoordinates.find((location) => {
        return location.name.toLocaleLowerCase() === name.toLocaleLowerCase();
    });
};

export const knownLocationToGeoCoordinates = (
    knownLocation: string
): { latitude: number; longitude: number } => {
    const location = findLocationByName(knownLocation);
    if (!location) {
        throw new LocationNotFoundError(knownLocation);
    }
    return {
        latitude: location.latitude,
        longitude: location.longitude,
    };
};

export class LocationNotFoundError extends CademyError {
    constructor(query: string) {
        super(
            'Search/LocationNotFound',
            'Location not found',
            `We were unable to find the location "${query}", check for typos or try making the search more specific.`,
            404
        );
    }
}

export const locationFilterToGeoFilter = async (
    locationFilter: LocationFilter
): Promise<
    SearchIndexCourseFilters['geo_coordinates'] | SearchIndexEducatorFilters['geo_coordinates']
> => {
    const radius = convertRadiusToMeters(locationFilter.radius);
    if ('known_location' in locationFilter) {
        return {
            radius,
            ...knownLocationToGeoCoordinates(locationFilter.known_location),
        };
    } else {
        const queriedCoordinates = await queryLocationToGeoCoordinates(locationFilter.query);
        if (queriedCoordinates === null) {
            throw new LocationNotFoundError(locationFilter.query);
        }
        return {
            radius,
            ...queriedCoordinates,
        };
    }
};
